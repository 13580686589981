<template>
  <div>
    <RecipientDetailsModal
      v-if="selectedRecipient && showViewRecipient"
      v-model="showViewRecipient"
      :selected-recipient="selectedRecipient"
      :confirm-text="$t('ComponentRecipientDetailsModal.ButtonContinue').value"
      @edit="editRecipient()"
      @delete="deleteRecipient"
      @goBack="$router.go(-1)"
      @confirm="confirm"
    />
    <DeleteRecipientDialog
      v-if="selectedRecipient"
      :value="showDeleteRecipient"
      :recipient="selectedRecipient"
      @success="$router.toParentRoute()"
      @cancel="cancelDeleteRecipient()"
    />
  </div>
</template>

<script>
import { useRecipientsStore, useSendMoneyStore, useI18nStore } from '@galileo/stores'
import { useRouter } from '@galileo/composables/useRouter'
import { computed, ref, reactive, onBeforeMount } from '@vue/composition-api'
import RecipientDetailsModal from '@galileo/components/Views/Recipient/RecipientDetailsModal'
import DeleteRecipientDialog from '@galileo/components/Views/Recipient/DeleteRecipientDialog'

export default {
  name: 'RecipientDetails',
  components: {
    RecipientDetailsModal,
    DeleteRecipientDialog,
  },
  props: {
    isQuickTransfer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()

    const recipientsStore = useRecipientsStore()
    const sendMoneyStore = useSendMoneyStore()
    const router = useRouter()
    const showViewRecipient = ref(true)

    const showDeleteRecipient = ref(false)

    const recipientId = router.history.current.params.recipientId

    const selectedRecipient = computed(() => {
      const recipient = recipientsStore.recipients.find((recipient) => {
        return recipient.id === recipientId
      })
      return recipient
    })

    const editRecipient = () => {
      showViewRecipient.value = false
    }

    const deleteRecipient = () => {
      showDeleteRecipient.value = true
    }

    const cancelDeleteRecipient = () => {
      showDeleteRecipient.value = false
    }

    const confirm = () => {
      sendMoneyStore.form.recipient = selectedRecipient.value
      router.go(-1)
    }

    return {
      showViewRecipient,
      showDeleteRecipient,
      editRecipient,
      deleteRecipient,
      cancelDeleteRecipient,
      selectedRecipient,
      confirm,
      $t,
    }
  },
}
</script>

<style scoped></style>
